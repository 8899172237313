import { FpDirClient, IContactsHR, TimeSpan } from "@tcs-rliess/fp-core";
import Aigle from "aigle";
import { sortBy } from "lodash-es";
import { DateTime } from "luxon";

import { BaseStoreSingle } from "../BaseStoreSingle";

interface Item extends IContactsHR {
	dscaid: number;
}

export class HRContractStore extends BaseStoreSingle<IContactsHR, number> {
	private dirClient = new FpDirClient({ use: "HRContractStore" });

	protected itemId(item: IContactsHR): number {
		return (item as Item).dscaid;
	}

	// 2024-01-19 - [PR] We need a different solution for this. This takes a long time to load.
	protected async fetchIdList(idList: number[]): Promise<IContactsHR[]> {
		const response = await Aigle.mapLimit(idList, 3, async id => {
			const item = await this.dirClient.contractHrGet(this.app.ctx.dscid, id);
			(item as Item).dscaid = id;
			return item;
		});
		return response;
	}

	public async getCurrentVersion(id: number): Promise<IContactsHR["rows"][number]> {
		const contract = await this.getId(id);
		const dateNow = DateTime.now();
		let datesArr = [];
		for (const item of contract?.rows) { datesArr.push(item.valid_from); }
		datesArr = datesArr.filter(date => DateTime.fromISO(date?.toString()) < dateNow);
		const validNow = datesArr[datesArr?.length-1];
		return contract?.rows.find(item => item.valid_from === validNow);
	}

	public async getContractRange({ dscaid }:{ dscaid: number }): Promise<[TimeSpan, IContactsHR["rows"][number]][]> {
		// all contracts for the given dscaid
		let contactHrData = (await this.getId(dscaid)).rows;
		contactHrData = sortBy(contactHrData, "valid_from");
		contactHrData = contactHrData.filter(e => e.valid_from !== 21991231);
		const ranges: [TimeSpan, typeof contactHrData[0]][] = [];
		for(let idx = 0; idx < contactHrData.length; idx++) {
			const from = DateTime.fromFormat(contactHrData[idx].valid_from.toString(), "yyyyMMdd");
			const to = contactHrData[idx].next_valid_from ? DateTime.fromFormat(contactHrData[idx].next_valid_from.toString(), "yyyyMMdd") : null;
			const range = new TimeSpan(from, to);
			ranges.push([ range, contactHrData[idx] ]);
		}
		return ranges;
	}

	public async findContractForDate({ dscaid, date }:{ dscaid: number, date: DateTime }): Promise<IContactsHR["rows"][number]> {
		const ranges = await this.getContractRange({ dscaid });
		const contract = ranges.find(([ range ]) => range.includes(date));
		return contract ? contract[1] : null;
	}

	/**
	 * Find the contract for the given date from the given range
	 * the range is the return value of getContractRange!
	 */
	public findContractForDateFromRange({ date, range }: { date: DateTime, range: Awaited<ReturnType<InstanceType<typeof HRContractStore>["getContractRange"]>>}): IContactsHR["rows"][number] {
		const contract = range.find(([ range ]) => range.includes(date));
		return contract ? contract[1] : null;
	}
	/**
	 * Find the contract for the given date from the given range
	 * the range is the return value of getContractRange!
	 */
	public findContractForRangeFromRange({ from, to, range }: { from?: DateTime, to?: DateTime, range: Awaited<ReturnType<InstanceType<typeof HRContractStore>["getContractRange"]>>}): IContactsHR["rows"] {
		const targetRange = new TimeSpan(from ?? null, to ?? null);
		const contract = range.filter(([ range ]) => range.intersects(targetRange));
		return contract.map(e => e[1]);
	}
}
