import { apiManager, FpApi, FpId } from "@tcs-rliess/fp-core";
import EventEmitter from "events";
import { castArray, groupBy, toString } from "lodash-es";
import { DateTime } from "luxon";

import { FleetplanApp } from "../../../../FleetplanApp";


type BaseParams = {
	from: number;
	to: number;
}

type ScheduleQueryParams = {
	type?: FpApi.Resource.Duty.ScheduleType;
	dscatid?: number[];
	checkedIn?: boolean;
}


type Query<T extends (number | boolean | string)> = `${"*" | T}`;
type ScheduleQuery = `${Query<FpApi.Resource.Duty.ScheduleType>},${Query<number>},${Query<boolean>}`;
type CheckInQuery = `${Query<number>}`;
type ScheduleKey = `${FpApi.Resource.Duty.ScheduleType},${number},${boolean}`;
type CheckInKey = `${number}`;

type CheckInQueryParams = {
	dscatid?: number[];
}


export class ScheduleBalanceStore {

	constructor(private app: FleetplanApp) {}
	// mapped by dscaid
	public buckets = new Map<number, ScheduleBalanceContactBucket>();
	// YYYYMM:DSCAID
	// private loadedRanges = new Set<`${number}:${number}`>();

	public async load(year: number, idList: number[]): Promise<void> {
		// figure out what to
		const loadedData = await apiManager.getService(FpApi.Resource.Duty.ScheduleBalanceService).get(this.app.ctx, {
			from: year * 100 + 1,
			to: year * 100 + 12,
			linkType: "dscaid",
			linkId: idList.map(e => e.toString()),
		});
		const grouped = groupBy(loadedData, e => e.linkId);
		for(const dscaid in grouped) {
			const bucket = this.buckets.get(parseInt(dscaid));
			if(bucket) {
				for(const data of grouped[dscaid]) {
					bucket.insertBalance(data);
				}
			} else {
				this.buckets.set(parseInt(dscaid), new ScheduleBalanceContactBucket(parseInt(dscaid), grouped[dscaid]));
			}
		}
	}
}


class ScheduleBalanceContactBucket extends EventEmitter {

	private checkInBuckets = new Map<number, BalanceBucket<CheckInKey, CheckInQuery, "checkIn">>();
	private scheduleBuckets = new Map<number, BalanceBucket<ScheduleKey, ScheduleQuery, "schedule">>();
	constructor(private _dscaid: number, public readonly originalData: FpApi.Resource.Duty.ScheduleBalance[]) {
		super();
		for(const data of originalData) {
			if(data.linkId === this.dscaid.toString()) {
				const schedule = new BalanceBucket<ScheduleKey, ScheduleQuery, "schedule">(this.dscaid, data.date, this);
				schedule.setData(data.schedule);
				schedule.setUpdated(DateTime.fromISO(data.scheduleUpdated));
				this.scheduleBuckets.set(data.date, schedule);

				const checkIn = new BalanceBucket<CheckInKey, CheckInQuery, "checkIn">(this.dscaid, data.date, this);
				checkIn.setData(data.checkIn);
				checkIn.setUpdated(DateTime.fromISO(data.checkInUpdated));
				this.checkInBuckets.set(data.date, checkIn);
			}
		}
	}
	public get dscaid() {
		return this._dscaid;
	}

	public insertBalance(balance: FpApi.Resource.Duty.ScheduleBalance): void {
		const schedule = this.scheduleBuckets.get(balance.date);
		if(schedule) {
			schedule.setData(balance.schedule);
			schedule.setUpdated(DateTime.fromISO(balance.scheduleUpdated));
		} else {
			const schedule = new BalanceBucket<ScheduleKey, ScheduleQuery, "schedule">(this.dscaid, balance.date, this);
			schedule.setData(balance.schedule);
			schedule.setUpdated(DateTime.fromISO(balance.scheduleUpdated));
			this.scheduleBuckets.set(balance.date, schedule);
		}

		const checkIn = this.checkInBuckets.get(balance.date);
		if(checkIn) {
			checkIn.setData(balance.checkIn);
			checkIn.setUpdated(DateTime.fromISO(balance.checkInUpdated));
		} else {
			const checkIn = new BalanceBucket<CheckInKey, CheckInQuery, "checkIn">(this.dscaid, balance.date, this);
			checkIn.setData(balance.checkIn);
			checkIn.setUpdated(DateTime.fromISO(balance.checkInUpdated));
			this.checkInBuckets.set(balance.date, checkIn);
		}
	}


	public queryMany(type: "schedule", from: number, to: number, params: (Omit<ScheduleQueryParams, keyof BaseParams>)[]): ScheduleBalanceResponse[];
	public queryMany(type: "checkIn", from: number, to: number, params: (Omit<CheckInQueryParams, keyof BaseParams>)[]): ScheduleBalanceResponse[];
	public queryMany(type: "schedule" | "checkIn", from: number, to: number, params: {}[]): ScheduleBalanceResponse[] {
		switch(type) {
			case "schedule":
				return this.querySchedule({ from, to }, castArray(params)).map(e => ScheduleBalanceResponse.fromObject(e));
			case "checkIn":
				return this.queryCheckIn({ from, to }, castArray(params)).map(e => ScheduleBalanceResponse.fromObject(e));
		}
	}

	public query(type: "schedule", from: number, to: number, params: ScheduleQueryParams): ScheduleBalanceResponse;
	public query(type: "checkIn", from: number, to: number, params: CheckInQueryParams): ScheduleBalanceResponse;
	public query(type: "schedule" | "checkIn",  from: number, to: number, params: {}): ScheduleBalanceResponse {
		switch(type) {
			case "schedule":
				return this.querySchedule({ from, to }, castArray(params)).map(e => ScheduleBalanceResponse.fromObject(e))[0];
			case "checkIn":
				return this.queryCheckIn({ from, to }, castArray(params)).map(e => ScheduleBalanceResponse.fromObject(e))[0];
		}
	}

	public add(type: "schedule", params: Required<Omit<ScheduleQueryParams, keyof BaseParams>>);
	public add(type: "checkIn", params: Required<Omit<CheckInQueryParams, keyof BaseParams>>);
	public add(type: "schedule" | "checkIn") {

	}

	public static makeKey(type: "schedule", params: Omit<ScheduleQueryParams, keyof BaseParams | "dscatid"> & { dscatid?: number }): ScheduleQuery;
	public static makeKey(type: "checkIn", params: Omit<CheckInQueryParams, keyof BaseParams | "dscatid"> & { dscatid?: number }): CheckInQuery;
	public static makeKey(type: string, params: {}): ScheduleQuery | CheckInQuery {
		switch(type) {
			case "schedule": {
				const typedParams = params as Omit<ScheduleQueryParams, keyof BaseParams | "dscatid"> & { dscatid?: number };
				return `${typedParams.type ?? "*"},${typedParams.dscatid ?? "*"},${toString(typedParams.checkedIn) ?? "*"}` as ScheduleQuery;
			}
			case "checkIn": {
				const typedParams = params as Omit<CheckInQueryParams, keyof BaseParams | "dscatid"> & { dscatid?: number };
				return `${typedParams.dscatid ?? "*"}` ;
			}
		}
	}

	private querySchedule(range: BaseParams, params: ScheduleQueryParams[]): {
		cnt: number;
		min: number;
		day: number;
		dur: number;
	}[]  {
		const responses = [];

		for(const param of params) {
			const response = {
				cnt: 0,
				min: 0,
				day: 0,
				dur: 0,
			};
			responses.push(response);
			const keys = param.dscatid?.map(e => ScheduleBalanceContactBucket.makeKey("schedule", {
				...param,
				dscatid: e,
			})) || [ ScheduleBalanceContactBucket.makeKey("schedule", {
				...param,
				dscatid: undefined,
			}) ];
			for(let i = range.from; i <= range.to; i++) {
				const value = this.scheduleBuckets.get(i)?.query(keys);
				if(value) {
					response.cnt += (value.cnt ?? 0);
					response.min += (value.min ?? 0);
					response.day += (value.day ?? 0);
					response.dur += (value.dur ?? 0);
				}
			}
		}
		return responses;
	}

	private queryCheckIn(range: BaseParams, params: CheckInQueryParams[]): {
		cnt: number;
		min: number;
		day: number;
		dur: number;
	}[] {


		const queryMapping = new Map<string, CheckInQuery[]>();
		const responseMapping = new Map<string, { cnt: number, min: number, day: number, dur: number }>();
		const order: string[] = [];

		for(const param of params) {
			const paramKey = FpId.new(); // used for later reconstruction
			const response = {
				cnt: 0,
				min: 0,
				day: 0,
				dur: 0,
			};
			// build queries
			const keys = param.dscatid?.map(e => ScheduleBalanceContactBucket.makeKey("checkIn", {
				...param,
				dscatid: e,
			})) || [ ScheduleBalanceContactBucket.makeKey("checkIn", {
				...param,
				dscatid: undefined,
			}) ];
			order.push(paramKey);
			queryMapping.set(paramKey, keys);
			responseMapping.set(paramKey, response);
		}

		for(let i = range.from; i <= range.to; i++) {
			const value = this.checkInBuckets.get(i)?.queryMap(queryMapping);
			if(!value) continue;
			if(value.size === 0) continue;
			for(const [ key, response ] of value) {
				const returnValue = responseMapping.get(key);
				returnValue.cnt += (response.cnt ?? 0);
				returnValue.min += (response.min ?? 0);
				returnValue.day += (response.day ?? 0);
				returnValue.dur += (response.dur ?? 0);
			}
		}
		return order.map(e => responseMapping.get(e) ?? { cnt: 0, min: 0, day: 0, dur: 0 });
	}
}

class BalanceBucket<Key extends string, Q extends string, BucketType extends "schedule" | "checkIn"> {
	// checkIn is same defined as schedule, we just chose schedule here.
	private data: FpApi.Resource.Duty.ScheduleBalance[BucketType] = {};
	private queryCache: Map<Q, { cnt: number, min: number, day: number, dur: number }> = new Map();
	public updated: DateTime;
	public get dscaid() {
		return this._dscaid;
	}

	public get timeSpan() {
		return this._timeSpan;
	}

	constructor(private _dscaid: number, private _timeSpan: number, private parent: ScheduleBalanceContactBucket) {}
	public query(query: Q | Q[]): { cnt: number, min: number, day: number, dur: number } {
		const queries = castArray(query);
		const response = {
			cnt: 0,
			min: 0,
			day: 0,
			dur: 0,
		};
		for(const key in this.data) {
			if(queries.some(q => this.isMatch(key, q))) {
				response.cnt += this.data[key].cnt ?? 0;
				response.min += this.data[key].min ?? 0;
				if("day" in this.data[key])
					response.day += this.data[key].day ?? 0;
				response.dur += this.data[key].dur ?? 0;
			}
		}

		queries.forEach(q => this.queryCache.set(q, response));
		return response;
	}

	public queryMap(queryMap: Map<string, Q[]>): Map<string, { cnt: number, min: number, day: number, dur: number }> {
		// mapped by id
		const returnMap = new Map<string, { cnt: number, min: number, day: number, dur: number }>(Array.from(queryMap.keys()).map(e => [ e, { cnt: 0, min: 0, day: 0, dur: 0 }]));
		for(const key in this.data) {
			queryMap.forEach((queries, id) => {
				if(queries.some(q => this.isMatch(key, q))) {
					const response = returnMap.get(id);
					response.cnt += this.data[key].cnt ?? 0;
					response.min += this.data[key].min ?? 0;
					if("day" in this.data[key])
						response.day += this.data[key].day ?? 0;
					response.dur += this.data[key].dur ?? 0;
				}
			});
		}

		queryMap.forEach((_, id) => {
			const response = returnMap.get(id);
			queryMap.get(id).forEach(q => this.queryCache.set(q, response));
		});
		return returnMap;
	}

	public setData(data: FpApi.Resource.Duty.ScheduleBalance[BucketType]): void {
		this.data = data;
	}

	public setUpdated(dateTime: DateTime): void {
		this.updated = dateTime;
	}

	public add(key: Key, value: { cnt: number, min: number, day: number, dur: number }): void {
		const current = this.data[key];
		if(current) {
			current.cnt += value.cnt;
			current.min += value.min;
			current.dur += value.dur;
			if("day" in current)
				current.day += value.day;
		} else {
			(this.data as any)[key] = value;
		}
		this.invalidateKey(key);
	}

	protected invalidateKey(key: Key): void {
		this.queryCache.forEach((value, query) => {
			if(this.isMatch(key, query)) {
				this.queryCache.delete(query);
				this.parent.emit(`${this.dscaid}:invalidate:${query}`);
			}
		});
	}

	protected isEqual<T extends (number | boolean | string)>(key: string, queryKey: Query<T>) {
		if(queryKey === "*") return true;
		return key === queryKey;
	}

	protected isMatch<K extends string, Q extends string>(key: K, query: Q): boolean {
		const keys = key.split(",");
		const queries = query.split(",");
		return keys.every((key, i) => this.isEqual(key, queries[i] as Query<any>));
	}
}


export class ScheduleBalanceResponse {
	cnt: number;
	min: number;
	day: number;
	dur: number;

	constructor(cnt: number, min: number, day: number, dur: number) {
		this.cnt = cnt;
		this.min = min;
		this.day = day;
		this.dur = dur;
	}

	public static fromObject(obj: { cnt: number, min: number, day: number, dur: number }) {
		return new ScheduleBalanceResponse(obj.cnt, obj.min, obj.day, obj.dur);
	}

	public add(response: ScheduleBalanceResponse) {
		return new ScheduleBalanceResponse(this.cnt + response.cnt, this.min + response.min, this.day + response.day, this.dur + response.dur);
	}
}
