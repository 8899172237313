import { BaseCertificate, Category, ClientCategoryOption, ClientCategoryUtil, DirContact, DirectoryNode, FPEvent, FpApi, FpDirClientState, FpLegClient, HazardNode, LandingField, SubCertificate, TreeUtil, apiManager } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import Aigle from "aigle";

import { type FleetplanApp } from "../../FleetplanApp";
import { FPSet, LandingFieldDistrict } from "../ClientStore/impls";
import { mapFpLegToFpApiLeg } from "../FpLegHelper";

import type { LoadRelation } from "./Resolver";

export class DataLoader {
	private app: FleetplanApp

	constructor(app: FleetplanApp) {
		this.app = app;
	}

	private buildMap<T>(data: Array<T>, key: string): Map<string | number, T> {
		return new Map(
			data
				.filter(e => e != null)
				.map(e => [ e[key], e ])
		);
	}

	public async load(linkType: string, relations: LoadRelation[]): Promise<Map<string | number, unknown>>{
		switch (linkType) {
			case "dscaid": return this.buildMap(this.loadDscaid(relations), "id");
			case "fpdbvmid": return this.buildMap(this.loadFpdbvmid(relations), "fpdbvmid");
			case "fpvid": return this.buildMap(this.loadFpvid(relations), "id");
			case "category":
			case "dscatid": return this.buildMap(await this.loadDscatid(relations), "id");
			case "dscatid:old": return this.buildMap(this.loadDscatidOld(relations), "id");
			case "dspiid": return this.buildMap(await this.loadDspiid(relations), "id");
			case "fplaid": return this.buildMap(this.loadFplaid(relations), "id");
			case "fplaid_district": return this.buildMap(await this.loadFplaid_district(relations), "id");
			case "fpdirid":
			case "fpdirloc":
			case "fpdirpos":
			case "fpdirgrp":
			case "fpdirsec": return this.loadDirectoryNodes(relations);
			case "dsrlid": return this.buildMap(await this.loadDsrlid(relations), "id");
			case "dssid": return this.buildMap(await this.loadDssid(relations), "dssid");
			case "dscalid": return this.buildMap(await this.loadDscalid(relations), "id");
			case "dsqmprcsid": return this.buildMap(await this.loadDsqmprcsid(relations), "id");
			case "dsqmrepid": return this.buildMap(await this.loadDsqmrepid(relations), "id");
			case "dsqmauid": return this.buildMap(await this.loadDsqmauid(relations), "id");
			case "dscdid": return this.buildMap(await this.loadDscdid(relations), "id");
			case "dscdsid": return this.buildMap(await this.loadDscdsid(relations), "id");
			case "dscdxcid": return this.buildMap(await this.loadDscdxcid(relations), "id");
			case "dsbid": return this.buildMap(await this.loadDsbid(relations), "id");
			case "dseid": return this.buildMap(await this.loadDseid(relations), "id");
			case "dsdocid": return this.buildMap(await this.loadDsdocid(relations), "id");
			case "fpresloglid": return this.buildMap(await this.loadFpresloglid(relations), "id");
			case "fpreslogid": return this.buildMap(await this.loadFpreslogid(relations), "id");
			case "dscid": return this.buildMap(await this.loadDscid(relations), "id");
			case "dsqmprocid": return this.buildMap(await this.loadDsqmprocid(relations), "id");
			case "dsqmctrlid": return this.buildMap(await this.loadDsqmctrlid(relations), "id");
			case "dsqmrskid": return this.buildMap(await this.loadDsqmrskid(relations), "id");
			case "dsbiid": return this.buildMap(await this.loadDsbiid(relations), "id");
			case "priceUnit": return this.buildMap(await this.loadPriceUnit(relations), "idName");
			case "dsstocklid": return this.buildMap(await this.loadDsstocklid(relations), "id");
			case "dsttid": return this.buildMap(await this.loadDsttid(relations), "id");
			case "dswfid": return this.buildMap(this.loadDswfid(relations), "id");
			case "dsrsid": return this.buildMap(this.loadDsrsid(relations), "id");
			case "dsrschid": return this.buildMap(await this.loadDsrschid(relations), "id");
			case "dsrdsid": return this.buildMap(this.loadDsrdsid(relations), "id");
			case "dserrid": return this.buildMap(this.loadDserrid(relations), "id");
			case "dsqmhzid": return this.buildMap(this.loadDsqmhzid(relations), "id");
			case "dsrswpid": return this.buildMap(await this.loadDsrswpid(relations), "id");
			case "dsigid": return this.buildMap(await this.loadDsigid(relations), "id");
			case "dsrlsid": return this.buildMap(await this.loadDsrlsid(relations), "id");
			case "dscaid:state": return this.buildMap(await this.loadDscaidState(relations), "id");
			default: {
				console.error(`Unknown link type ${linkType}, or switch case missing`);
				return new Map();
			}
		}
	}

	private loadDscaid(relations: LoadRelation[]): Array<DirContact> {
		return this.app.store.contact.getIds(relations.map(r => r.dscaid));
	}

	private loadFpdbvmid(relations: LoadRelation[]): Array<FpApi.Resource.AircraftModel> {
		return this.app.store.resource.aircraftModel.getIds(relations.map(r => r.fpdbvmid));
	}

	private loadFpvid(relations: LoadRelation[]): Array<FpApi.Resource.Aircraft> {
		return this.app.store.resource.aircraft.getIds(relations.map(r => r.fpvid));
	}

	private async loadDsqmrepid(relations: LoadRelation[]): Promise<Array<FpApi.Quality.Report.Report>> {
		const response = [];
		for (const relation of relations) {
			try {
				response.push(await this.app.store.qualityReport.getId(relation.dsqmrepid));
			} catch (err) {
				fpLog.error(err);
			}
		}
		return response;
	}

	private async loadDscatid(relations: LoadRelation[]): Promise<Array<Category>> {
		const all = await this.app.store.categoryUtil.getAll();
		const set = new Set(relations.map(r => r.dscatid));
		const ret: Array<Category> = [];

		const util = new TreeUtil(all, {
			getChildren: e => e.children,
			getKey: e => e.id
		});

		util.walk(e => {
			if (set.has(e.node.id)) {
				ret.push(e.node);
				set.delete(e.node.id);
			}
			if (set.size === 0) return true;
		});
		return ret;
	}

	private loadDscatidOld(relations: LoadRelation[]): Array<FpApi.System.SystemCategory> {
		return this.app.store.systemCategory.getIds(relations.map(r => r["dscatid:old"]));
	}

	private async loadDspiid(relations: LoadRelation[]): Promise<Array<FpApi.Product.ProductItem>> {
		return this.app.store.productItemStore.getAll();
	}

	private loadFplaid(relations: LoadRelation[]): Array<LandingField> {
		return this.app.store.landingField.getIds(relations.map(r => r.fplaid));
	}

	private async loadFplaid_district(relations: LoadRelation[]): Promise<Array<LandingFieldDistrict>> {
		await this.app.store.landingField.ensureDistricts();

		const items = [];
		for (const relation of relations) {
			const district = this.app.store.landingField.allDistrictsMap.get(relation.fplaid_district);
			if (district) items.push({
				...district,
				id: district.ZdbOrtId
			});
		}

		return items;
	}

	private loadDirectoryNodes(relations: LoadRelation[]): Map<number, DirectoryNode> {
		const items = new Map<number, DirectoryNode>();
		const set = new Set(relations.map(r => r.fpdirid));
		const util = TreeUtil.fromDirectoryNodes(this.app.directory);

		util.walk(info => {
			if (set.has(info.node.id)) {
				items.set(info.node.id, info.node);
				set.delete(info.node.id);
			}
			if (set.size === 0) return true; // stop walking
		});
		return items;
	}

	private loadDsrsid(relations: LoadRelation[]): Array<FpApi.Resource.Duty.Shift> {
		const response = [];
		for (const relation of relations) {
			try {
				response.push(this.app.store.resource.shift.getId(relation.dsrsid));
			} catch (err) {
				fpLog.error(err);
			}
		}
		return response;
	}

	/** resource schedule */
	private async loadDsrschid(relations: LoadRelation[]): Promise<Array<FpApi.Resource.Duty.Schedule>> {
		return await this.app.store.resource.schedule.getIdList(relations.map(r => r.dsrschid));
	}

	private loadDsqmhzid(relations: LoadRelation[]): Array<HazardNode> {
		const hazardTree = this.app.store.fpDir.hazard.getTree();
		const set = new Set(relations.map(r => r.dsqmhzid));
		const ret: Array<HazardNode> = [];
		hazardTree.walk(e => {
			if (set.has(e.node.id)) {
				set.delete(e.node.id);
				ret.push(e.node);
			}
			if (set.size === 0) return true;
		});
		return ret;
	}

	private loadDsrdsid(relations: LoadRelation[]): Array<FpApi.Resource.Duty.Setup> {
		return this.app.store.resource.setup.getIds(relations.map(r => r.dsrdsid));
	}

	private loadDserrid(relations: LoadRelation[]): Array<ClientCategoryOption<FpApi.Calendar.Event.EventResourceRole>> {
		const response = [];
		for (const relation of relations) {
			try {
				const opt = ClientCategoryUtil.byEnum(FpApi.Calendar.Event.EventResourceRole).getOption(relation.dserrid);
				if (opt) response.push({
					...opt,
					// FpGrid needs an ID!
					id: opt.value
				});
			} catch (err) {
				fpLog.error(err);
			}
		}
		return response;
	}

	/** security role */
	private async loadDsrlid(relations: LoadRelation[]): Promise<Array<FpApi.Security.Role>> {
		return apiManager
			.getService(FpApi.Security.RoleService)
			.get(this.app.ctx);
	}

	/** controlled document */
	private loadDssid(relations: LoadRelation[]): Promise<FpApi.ControlledDocument.Document[]> {
		return this.app.store.controlledDocument.document.getIdList(relations.map(r => r.dssid));
	}

	private async loadDscalid(relations: LoadRelation[]): Promise<FpApi.Calendar.Calendar[]> {
		return apiManager
			.getService(FpApi.Calendar.CalendarService)
			.get(this.app.ctx, {});
	}

	private async loadDsqmprcsid(relations: LoadRelation[]): Promise<FpApi.Quality.Process.Process[]> {
		return apiManager
			.getService(FpApi.Quality.Process.ProcessService)
			.get(this.app.ctx, { ids: relations.map(r => r.dsqmprcsid) });
	}

	private async loadDsqmauid(relations: LoadRelation[]): Promise<FpApi.Quality.Audit.Audit[]> {
		return this.app.store.qualityAudit.getIdList(relations.map(r => r.dsqmauid));
	}

	private async loadDscdid(relations: LoadRelation[]): Promise<Array<BaseCertificate> > {
		if (this.app.store.certificateV3Store.initialLoadComplete) {
			return relations.map(r => this.app.store.certificateV3Store.getBaseCertificateById(r.dscdid));
		} else {
			const res = await this.app.store.certificateV3Store.fetchCertificates({ id: relations.map(r => r.dscdid) });

			if (res.rows.length) {
				// one base certificate is returned and the cert we're looking for is in members!
				const target: Array<BaseCertificate> = [];
				for (const cert of res.rows) {
					target.push(cert);
				}
				return target;
			}

			return [];
		}
	}

	private async loadDscdsid(relations: LoadRelation[]): Promise<Array<FPSet>> {
		if (this.app.store.certificateV3Store.initialSetLoadComplete) {
			return relations.map(r => this.app.store.certificateV3Store.setsObj[r.dscdsid]);
		} else {
			await this.app.store.certificateV3Store.ensureSets();
			return relations.map(r => this.app.store.certificateV3Store.setsObj[r.dscdsid]);
		}
	}

	private async loadDscdxcid(relations: LoadRelation[]): Promise<Array<SubCertificate> > {
		if (this.app.store.certificateV3Store.initialLoadComplete) {
			return relations.map(r => this.app.store.certificateV3Store.getCertificateById(r.dscdxcid));
		} else {
			const res = await this.app.store.certificateV3Store.fetchCertificates({
				id_subcert: relations.map(r => r.dscdxcid),
			});

			if (res.rows.length) {
				// one base certificate is returned and the cert we're looking for is in members!
				const target: Array<SubCertificate> = [];
				for (const baseCert of res.rows) {
					for (const cert of baseCert.members) {
						if (relations.some(r => r.dscdxcid === cert.id)) {
							target.push(cert);
						}
					}
				}
				return target;
			}

			return [];
		}
	}

	// TODO: Only get the actual bookings defined by ids
	private async loadDsbid(relations: LoadRelation[]): Promise<Array<FpApi.Booking.Booking>> {
		return apiManager
			.getService(FpApi.Booking.BookingService)
			.get(this.app.ctx, { id: relations.map(r => r.dsbid) });
	}

	private async loadDseid(relations: LoadRelation[]): Promise<Array<FPEvent>> {
		const events = [];
		for (const relation of relations) {
			events.push(await this.app.store.event.getId(relation.dseid));
		}
		return events ?? [];
	}

	private async loadDsdocid(relations: LoadRelation[]): Promise<Array<FpApi.Finance.Document>> {
		return apiManager
			.getService(FpApi.Finance.DocumentService)
			.get(this.app.ctx, { ids: relations.map(r => r.dsdocid) });
	}

	private async loadFpresloglid(relations: LoadRelation[]): Promise<Array<FpApi.Resource.AircraftLeg.AircraftLeg>> {
		if (this.app.flags.flightOpsNew) {
			const client = new FpLegClient({ use: "FpGridLoader" });
			const legs = await client.getLegs_byIds(this.app.dscid, {
				ids: relations.map(r => r.fpresloglid),
			});
			return Aigle.map(legs.rows, e => mapFpLegToFpApiLeg(e, this.app));
		} else {
			return apiManager
				.getService(FpApi.Resource.AircraftLeg.AircraftLegService)
				.get(this.app.ctx, { ids: relations.map(r => r.fpresloglid) });
		}
	}

	private async loadFpreslogid(relations: LoadRelation[]): Promise<Array<FpApi.Resource.AircraftLeg.AircraftLogbook>> {
		return apiManager
			.getService(FpApi.Resource.AircraftLeg.AircraftLogbookService)
			.get(this.app.ctx, { ids: relations.map(r => r.fpreslogid) });
	}

	private async loadDscid(relations: LoadRelation[]): Promise<Array<FpApi.Customer.Customer>> {
		return apiManager
			.getService(FpApi.Customer.CustomerService)
			.getIds(this.app.ctx, { ids: relations.map(r => r.dscid) });
	}

	private async loadPriceUnit(relations: LoadRelation[]): Promise<Array<FpApi.System.SystemCategory>> {
		return this.app.store.systemCategory.getType("price_unit");
	}

	private async loadDsqmctrlid(relations: LoadRelation[]): Promise<FpApi.Quality.Control.Control[]> {
		const controls = await apiManager
			.getService(FpApi.Quality.Control.ControlService)
			.getControls(this.app.ctx, { ids: relations.map(r => r.dsqmctrlid) });

		return controls;
	}

	private async loadDsqmprocid(relations: LoadRelation[]): Promise<FpApi.Quality.Procedure.Procedure[]> {
		return apiManager
			.getService(FpApi.Quality.Procedure.ProcedureService)
			.get(this.app.ctx, { ids: relations.map(r => r.dsqmprocid) });
	}

	private async loadDsqmrskid(relations: LoadRelation[]): Promise<FpApi.Quality.Risk.Risk[]> {
		return apiManager
			.getService(FpApi.Quality.Risk.RiskService)
			.get(this.app.ctx, { ids: relations.map(r => r.dsqmrskid) });
	}

	private async loadDsbiid(relations: LoadRelation[]): Promise<FpApi.Booking.BookingItem[]> {
		return this.app.store.bookingItem.get({ id: relations.map(r => r.dsbiid) });
	}

	private async loadDsstocklid(relations: LoadRelation[]): Promise<FpApi.Stock.Location[]> {
		return apiManager
			.getService(FpApi.Stock.LocationService)
			.get(this.app.ctx, { id: relations.map(r => r.dsstocklid) });
	}

	private async loadDsttid(relations: LoadRelation[]): Promise<FpApi.Tag.Tag[]> {
		return this.app.store.tags.getIds(relations.map(r => r.dsttid));
	}

	private loadDswfid(relations: LoadRelation[]): FpApi.System.Workflow.Workflow[] {
		return this.app.store.workflow.getAll();
	}

	private async loadDsrswpid(relations: LoadRelation[]): Promise<FpApi.Resource.Duty.WorkPattern[]> {
		return this.app.store.resource.scheduleWorkPattern.getIds(relations.map(r => r.dsrswpid));
	}

	private async loadDsigid(relations: LoadRelation[]): Promise<FpApi.System.Notification.Group[]> {
		return this.app.store.notificationGroup.getIds(relations.map(r => r.dsigid));
	}

	private async loadDscaidState(relations: LoadRelation[]): Promise<FpDirClientState[]> {
		return this.app.store.contactState.getIdList(relations.map(r => r["dscaid:state"]));
	}

	private async loadDsrlsid(relations: LoadRelation[]): Promise<FpApi.Resource.Duty.LocationSetup[]> {
		return this.app.store.resource.locationSetup.getIdList(relations.map(r => r.dsrlsid));
	}
}
