import { CertificateStatus, SubCertificate } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { FleetplanApp } from "../../../FleetplanApp";
import { FpEventModel } from "../models";
import { SchedulerStore } from "../SchedulerStore";

import { BaseBuilder } from "./BaseBuilder";
import { BuilderState, RangeBuilderState } from "./BuilderState";

export class CertificateBuilder extends BaseBuilder {
	private loaded = false;

	constructor(store: SchedulerStore, app: FleetplanApp) {
		super(store, app);
	}

	public async buildRange(state: RangeBuilderState): Promise<void> {
		const loads: Promise<void>[] = [];

		if (this.store.configuration.events.certificate?.enabled && !this.loaded) loads.push(this.certificateEvents(state));

		await Promise.all(loads);
	}

	public handlePut(state: BuilderState, item: unknown): Promise<void> {
		return Promise.resolve();
	}

	public handleDelete(state: BuilderState, id: unknown): Promise<void> {
		return Promise.resolve();
	}

	private async certificateEvents(state: RangeBuilderState): Promise<void> {
		this.loaded = true;

		// root for showcasing, used in DRF
		if (![ 5105, 5067 ].includes(this.app.dscid)) return;

		// DRF Arbeitnehmerüberlassung Cert Id = 5007813
		// ADAC STAGE(!) Arbeitnehmerüberlassung Cert Id = 5009001
		const dscdid = this.app.store.settingsProject.getNumber("certificate.arbeitnehmerueberlassung.dscdid");
		if (!dscdid) return;

		// const dscdid = this.app.dscid === 5067 ? 5007813 : this.app.dscid === 5105 ? 5009001 : null;
		await this.app.store.certificateV3Store.refreshCertificateDataset({ id: [ dscdid ] }, null, true, null, true);
		const baseCert = this.app.store.certificateV3Store.getBaseCertificateByIdUnfiltered(dscdid);

		const certificates = baseCert.members;
		certificates.forEach(certificate => this.mapCertificate(state, certificate));
	}

	private mapCertificate(state: BuilderState, certificate: SubCertificate): void {
		// "(not) requested" have no date, skip them
		if ([ CertificateStatus.Requested, CertificateStatus.NotRequested ].includes(certificate.dscatidStatus)) {
			return;
		}

		this.makeEvent(state, () => {
			const startDate = new Date(certificate.dateExpiry);
			const endDate = DateTime.fromISO(certificate.dateExpiryRefresh).plus({ days: 1 }).toJSDate();

			const event = new FpEventModel({
				id: `dscdxcid:${certificate.id}`,
				startDate: this.toTimeZone(startDate),
				endDate: this.toTimeZone(endDate),
				draggable: false,
				resizable: false,
				name: certificate.runningno,

				fpLinkType: "dscdxcid",
				fpLinkId: certificate.id,
				fpData: certificate,
			});

			return event;
		}, function*() {
			yield { linkType: certificate.link_type, linkId: certificate.link_id };
		});
	}
}
